/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SaleGateway from "../../../shared/test-cases/pbc-payment-instruction-with-gateway-values.mdx";
import SaleNoGateway from "../../../shared/test-cases/pbc-payment-instruction-override-gateway.mdx";
import Refund from "../../../shared/test-cases/pbc-refund-instruction.mdx";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    h1: "h1",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3",
    ol: "ol",
    h4: "h4",
    pre: "pre",
    code: "code",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Before you can run live payments, we require you to run our test cases to make sure that you have correctly integrated against our API."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " If you have any issues when you run our test cases, email our integrations team at ", React.createElement(_components.a, {
    href: "mailto:integrationsupport@payroc.com"
  }, "integrationsupport@payroc.com"), ".")), "\n", React.createElement(_components.h1, null, "Before you begin"), "\n", React.createElement(_components.p, null, "To help you with testing, we provide you with:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Test environment"), "\n", React.createElement(_components.li, null, "Test cards"), "\n", React.createElement(_components.li, null, "Test values"), "\n"), "\n", React.createElement(_components.p, null, "You should also review our ", React.createElement(_components.a, {
    href: "/developer-resources/test-your-integration/pay-by-cloud#testing-recommendations"
  }, "testing recommendations"), "."), "\n", React.createElement(_components.h2, null, "Test environment"), "\n", React.createElement(_components.p, null, "Send your requests to our test environment:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test environment base URI:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1"
  }, "https://api.uat.payroc.com/v1")), "\n", React.createElement(Admonition, {
    type: "warning"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Important:"), " Use only test cards in our test environment.")), "\n", React.createElement(_components.h3, null, "Authenticating your requests"), "\n", React.createElement(_components.p, null, "Use our test Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.uat.payroc.com/authorize"
  }, "https://identity.uat.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(_components.h4, null, "Example request"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "curl --location --request POST  'https://identity.uat.payroc.com/authorize' --header 'x-api-key: <api key>'\n")), "\n", React.createElement(_components.h4, null, "Example response"), "\n", React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"access_token\": \"eyJhbGc....adQssw5c\",\n    \"expires_in\": 3600,\n    \"scope\": \"service_a service_b\",\n    \"token_type\": \"Bearer\"\n} \n")), "\n", React.createElement(_components.h4, null, "Example request header"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "curl\n-H \"Authorization: Bearer <access token>\"\n")), "\n", React.createElement(_components.h2, null, "Test cards"), "\n", React.createElement(Admonition, {
    type: "warning"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Important:"), " Use only test cards in our test environment.")), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Card scheme"), React.createElement(_components.th, {
    align: "left"
  }, "Card number"), React.createElement(_components.th, {
    align: "left"
  }, "Verification"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "Visa"), React.createElement(_components.td, {
    align: "left"
  }, "4761 7300 0000 0011"), React.createElement(_components.td, {
    align: "left"
  }, "Online only")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "Mastercard"), React.createElement(_components.td, {
    align: "left"
  }, "5413 3300 8960 4111"), React.createElement(_components.td, {
    align: "left"
  }, "Online only")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "Visa"), React.createElement(_components.td, {
    align: "left"
  }, "4761 7310 0000 0043"), React.createElement(_components.td, {
    align: "left"
  }, "Online or offline")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "Mastercard"), React.createElement(_components.td, {
    align: "left"
  }, "5413 3300 8960 4111"), React.createElement(_components.td, {
    align: "left"
  }, "Online or offline")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "Visa"), React.createElement(_components.td, {
    align: "left"
  }, "4761 7310 0000 0043"), React.createElement(_components.td, {
    align: "left"
  }, "Online or offline")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "Mastercard"), React.createElement(_components.td, {
    align: "left"
  }, "5413 3300 8960 4111"), React.createElement(_components.td, {
    align: "left"
  }, "Online or offline")))), "\n", React.createElement(_components.h2, null, "Test values"), "\n", React.createElement(_components.p, null, "To return a specific response from the gateway, change the value in the ", React.createElement(_components.strong, null, "amount"), " parameter to end in one of the following values:"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Value"), React.createElement(_components.th, {
    align: "left"
  }, "Response"), React.createElement(_components.th, {
    align: "left"
  }, "Example"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "01"), React.createElement(_components.td, {
    align: "left"
  }, "Declined"), React.createElement(_components.td, {
    align: "left"
  }, "501")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "02"), React.createElement(_components.td, {
    align: "left"
  }, "Referral"), React.createElement(_components.td, {
    align: "left"
  }, "1002")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "03"), React.createElement(_components.td, {
    align: "left"
  }, "CVV failure"), React.createElement(_components.td, {
    align: "left"
  }, "1403")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "Any other"), React.createElement(_components.td, {
    align: "left"
  }, "Authorized"), React.createElement(_components.td, {
    align: "left"
  }, "4541")))), "\n", React.createElement(_components.h2, null, "Testing recommendations"), "\n", React.createElement(_components.p, null, "When you test your integration, we recommend that you do the following:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Test error scenarios"), " – To check that your integration can handle errors, run scenarios that produce ", React.createElement(_components.a, {
    href: "/api/errors"
  }, "errors"), ". For example, test that your integration can handle the response when the value for a request parameter is in the wrong format."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Follow PCI DSS"), " – To help protect the payment data, make sure that your integration complies with ", React.createElement(_components.a, {
    href: "https://www.pcisecuritystandards.org/"
  }, "PCI DSS"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Test all card types"), " – If your integration accepts multiple card types, test your integration with each card type. For example, if your integration accepts EBT cards, you should test with an EBT card."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Refresh tokens for authentication"), " – ", React.createElement(_components.a, {
    href: "/api/authentication"
  }, "Bearer tokens"), " expire after a fixed period. Test your integration to make sure that it can correctly refresh Bearer tokens."), "\n"), "\n", React.createElement(_components.h2, null, "Test cases"), "\n", React.createElement(_components.p, null, "We provide you with test cases for the following:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/developer-resources/test-your-integration/pay-by-cloud#create-a-payment-instruction-using-options-that-you-configured-in-the-gateway"
  }, "Create a payment instruction using options that you configured in the gateway")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/developer-resources/test-your-integration/pay-by-cloud#create-a-payment-instruction-that-overrides-the-values-that-you-configured-in-the-gateway"
  }, "Create a payment instruction that overrides the values that you configured in the gateway")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/developer-resources/test-your-integration/pay-by-cloud#create-a-refund-instruction-for-when-the-card-isn%E2%80%99t-present"
  }, "Create a refund instruction for when the card isn’t present")), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " For some test cases you need to adjust the gateway settings, for example, to display a tip prompt. If you need help to adjust the gateway settings, contact your Payroc Integration Engineer.")), "\n", React.createElement(_components.h3, null, "Create a payment instruction using options that you configured in the gateway"), "\n", React.createElement(SaleGateway), "\n", React.createElement(_components.h3, null, "Create a payment instruction that overrides the values that you configured in the gateway"), "\n", React.createElement(SaleNoGateway), "\n", React.createElement(_components.h3, null, "Create a refund instruction for when the card isn’t present"), "\n", React.createElement(Refund));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
